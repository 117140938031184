import LoadingIcon from '@/components/Loading';
import ENDPOINTS from '@/constants/apiEndpoints';
import { COMMON_STYLES } from '@/constants/fuelStyles';
import { useFuelSignMessage } from '@/hooks/useFuelSignMessage';
import { useFuelWallet } from '@/hooks/useFuelWallet';
import { ModalCloseStatus, type IModalContentProps } from '@/hooks/useModal';
import useUserInfo from '@/store/useUserInfo';
import { fetcher } from '@/utils/request';
import { useWallet } from '@fuels/react';
import { notification } from 'antd';
import { useState } from 'react';
import FuelModalButton from '../FuelModalButton';

export interface BindFuelAddressContentProps {
  onCancel: (status: ModalCloseStatus) => void;
}

export function BindFuelAddressContent({
  onCancel
}: IModalContentProps<BindFuelAddressContentProps>) {
  const { wallet } = useWallet();
  const { connect, isConnected, disconnect, currentConnector } =
    useFuelWallet();
  const [loading, setLoading] = useState(false);
  const fuelAddress = wallet?.address?.toString();
  const { signMessage } = useFuelSignMessage();
  const { userInfo } = useUserInfo();

  const handleConnectFuelOrBind = async () => {
    if (!userInfo?.user_address) {
      notification.info({
        message: 'Please Login EVM Wallet First'
      });
      return;
    }
    if (isConnected) {
      try {
        setLoading(true);
        const sigRes = await fetcher(
          ENDPOINTS.fuelSignData,
          {
            method: 'POST',
            body: JSON.stringify({
              address: fuelAddress
            })
          },
          false
        );
        const { address, message } = sigRes || {};
        const signature = await signMessage(message);
        const bindRes = await fetcher(
          ENDPOINTS.fuelBindWallet,
          {
            method: 'POST',
            body: JSON.stringify({
              address,
              message,
              signature
            })
          },
          false
        );
        const { success, message: bindMessage } = bindRes || {};
        if (success) {
          setLoading(true);
          onCancel(ModalCloseStatus.SUCCESS);
        } else {
          notification.error({
            message: bindMessage ?? 'Bind Fuel address Error'
          });
        }
        setLoading(false);
      } catch (error: any) {
        console.error(error);
        notification.error({
          message: error?.message ?? 'Bind Fuel address Error'
        });
      } finally {
        setLoading(false);
      }
    } else {
      onCancel();
      // @ts-ignore
      connect(currentConnector?.name);
    }
  };

  return (
    <div
      onClick={e => e.stopPropagation()}
      className="w-full h-full bg-white p-4 space-y-4"
    >
      <div className={COMMON_STYLES.text}>
        To ensure that the mTokens you deposit in Fuel Ignition are accurately
        tracked and continue to earn CeDeFi points rewards, please bind your
        Fuel Ignition address.
      </div>
      <div className={COMMON_STYLES.flexColumn}>
        <div className={COMMON_STYLES.text}>Your fuel address</div>
        <div className="w-full min-h-[3.375rem] p-4 bg-[#00d994]/5 rounded-lg border border-[#05d9a6]/40 break-all">
          {isConnected
            ? fuelAddress
            : 'Connect wallet to display your Fuel address'}
        </div>
        <div
          onClick={() => disconnect()}
          className="text-red-500 cursor-pointer"
        >
          Disconnect the wallet to change the address
        </div>
      </div>
      <FuelModalButton
        onButtonClick={handleConnectFuelOrBind}
        text={
          loading ? (
            <LoadingIcon />
          ) : isConnected ? (
            'Bind'
          ) : (
            'Connect Fuel Wallet'
          )
        }
        className={loading ? 'opacity-50 cursor-not-allowed' : ''}
      />
    </div>
  );
}

const Content = (props: IModalContentProps<BindFuelAddressContentProps>) => (
  <BindFuelAddressContent {...props} />
);

export default Content;
