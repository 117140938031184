import arrowDown from '@/assets/arrow-down-bold.svg';
import arrowRight from '@/assets/arrow-right-bold.svg';
import arrowUp from '@/assets/arrow-up-bold.svg';
import InfoIcon from '@/assets/svgs/info-gray.svg';
import LoadingIcon from '@/components/Loading';
import NewButton from '@/components/NewButton';
import { MTokenInfo, MyPoint } from '@/components/SubNav';
import config from '@/config';
import ENDPOINTS from '@/constants/apiEndpoints';
import { useResponsive } from '@/hooks/useResonposive';
import { useEarnMoreStatus } from '@/store/useEarnMoreInfo';
import { useMindInfo } from '@/store/useMindInfo';
import { Popover } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';
import FuelBanner from './FuelBanner';
interface IProps {
  mindInfoSum: string;
  item: IEarnMoreItem;
}

export function EarnMoreCollapse({ mindInfoSum, item }: IProps) {
  const isMindNetwork = item?.title === 'Mind Network';
  const isFuel = item?.title === 'Fuel';
  const [show, setShow] = useState(isFuel);
  return (
    <div className="flex bg-green-bg-2 flex-col p-4 border rounded-[16px] w-full border-primary-green-border ">
      <div
        onClick={() => {
          setShow(preState => !preState);
        }}
        className="flex flex-col sm:flex-row gap-4 justify-between items-center cursor-pointer"
      >
        <div className="flex max-md:flex-col max-md:items-start items-center gap-2">
          {item?.is_new ? <NewButton /> : null}
          <div className="flex items-center gap-2">
            <img
              className="rounded-[48px] w-[48px] h-[48px]"
              src={item.logo}
            ></img>
            <div className="flex flex-col gap-2">
              <div className="flex">
                <p className="text-[16px] font-[500] mr-2">{item.title}</p>
                <p className="gradient-bg-1 py-1 px-4 rounded-[20px] text-white text-[12px] font-[500]">
                  {item.category}
                </p>
              </div>
              <div className="text-[14px] text-black-title">
                {item.description}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row items-center">
          {isFuel && <FuelBanner />}
          <div className="ml-2">
            {show ? <img src={arrowUp} /> : <img src={arrowDown} />}
          </div>
        </div>
      </div>
      {show && (
        <div className="mt-6 flex max-md:flex-col">
          {isMindNetwork ? (
            <div className="flex flex-col justify-center items-center w-[284px] max-md:w-full max-md:mb-2 mr-2 bg-green-bg-2 p-4 border rounded-[8px] border-primary-green-border">
              <div className="flex items-center">
                <div className="mr-2 text-xl">vFHE Rewards</div>
                <Popover
                  overlayInnerStyle={{
                    padding: '0',
                    boxShadow: 'none'
                  }}
                  arrow={false}
                  placement="rightBottom"
                  content={() => (
                    <div className="flex flex-col bg-[#1D314C99] text-white p-2 rounded-[4px]">
                      <span>Total vFHE rewards earned by holding mToken.</span>
                      <span>Rewards updated daily at 1:00 AM UTC</span>
                    </div>
                  )}
                  trigger="hover"
                >
                  <img src={InfoIcon} width={17} height={17} alt={'infoIcon'} />
                </Popover>
              </div>
              <div className="md:text-[32px]">
                {Number(mindInfoSum).toFixed(4) ?? '-'}
              </div>
            </div>
          ) : null}
          <div className="w-full flex gap-2 flex-col">
            {(item?.protocols || []).map((item, index) => {
              return (
                <div
                  key={index}
                  className={classNames(
                    'flex justify-between bg-green-bg-2 p-4 border rounded-[8px] w-full border-primary-green-border',
                    item?.url ? 'cursor-pointer' : 'cursor-not-allowed'
                  )}
                  onClick={() => {
                    if (!item.url) return false;
                    window.open(item.url, '_blank', 'noreferrer');
                  }}
                >
                  <span className="text-[14px]">{item.title}</span>
                  {!item.url ? (
                    <Popover
                      overlayInnerStyle={{
                        padding: '0',
                        boxShadow: 'none'
                      }}
                      arrow={false}
                      placement="right"
                      content={() => (
                        <div className="bg-[#1D314C99] text-white p-2 rounded-[4px]">
                          Coming Soon
                        </div>
                      )}
                      trigger="hover"
                    >
                      <img src={arrowRight} className="ml-2" />
                    </Popover>
                  ) : (
                    <img src={arrowRight} className="ml-2" />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
export default function EarnMore() {
  const { isMobile } = useResponsive();
  const { data, isLoading } = useEarnMoreStatus();
  const { data: mindInfoData } = useMindInfo();
  const mindInfoSum = mindInfoData?.data?.sum || '';
  const list = data?.data?.project_list || [];

  const handleClearBoundedAddresses = async () => {
    await fetch(ENDPOINTS.fuelDelWallet, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({})
    });
  };

  return (
    <div>
      {isMobile && (
        <div className="w-full flex flex-col items-center mb-4 md:hidden">
          <MyPoint />
          <MTokenInfo />
        </div>
      )}
      <div className="flex flex-col bg-white-80 justify-between px-6 py-12 items-center w-full border border-primary-green-border rounded-[16px] mb-4">
        <div className="text-[24px] text-black-title font-[400] mb-2">
          Earn more with your mETH, mBTC, and mUSD
        </div>
        <div className="text-[16px] text-black-title/80 font-[400]">
          Discover the Manta Pacific ecosystem with mETH, mBTC, and mUSD to
          unlock DeFi yields
        </div>
      </div>
      <div className="flex flex-col bg-white-80 justify-between p-6 items-center w-full border border-primary-green-border rounded-[16px] mb-4">
        <div className="text-[16px] text-black-title font-[500] mb-6 text-left w-full">
          How to Earn
        </div>
        {config.IS_TESTNET ? (
          <div
            onClick={handleClearBoundedAddresses}
            className="cursor-pointer text-[12px] text-red-500 font-[400]"
          >
            Clear My Bounded Addresses (Only for Test use)
          </div>
        ) : null}
        {isLoading ? (
          <div className="flex w-full justify-center pb-[60px]">
            <LoadingIcon isDark={true} />
          </div>
        ) : (
          <div className="flex flex-col w-full gap-4">
            {list.map(item => {
              return (
                <EarnMoreCollapse
                  mindInfoSum={mindInfoSum}
                  item={item}
                  key={item.title}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
