import arrowRight from '@/assets/fuel/arrowRight.svg';
import close from '@/assets/fuel/close.svg';
import BindFuelAddress from './BindFuelAddress';
interface FuelBannerProps {
  onClose?: () => void;
  learnMoreLink?: string;
}

export const FuelBanner = ({
  onClose,
  learnMoreLink = 'https://app.fuel.network/earn-points/deposit'
}: FuelBannerProps) => {
  const rowClassName = 'w-full inline-flex justify-between items-center gap-1';
  const textClassName = 'grow basis-0';
  const buttonClassName = 'font-medium flex items-center';

  return (
    <div className="w-full px-6 sm:px-12 mt-4">
      <div className="w-full sm:h-[75px] px-4 py-2 bg-fuel-primary/40 rounded-lg border border-fuel-primary flex-col justify-center items-start gap-1 inline-flex text-black-title text-sm">
        <div className={rowClassName}>
          <div className={`${textClassName} font-medium`}>
            Fuel Phase 2 Points are now live!
          </div>
          <button onClick={onClose} className={buttonClassName}>
            <img src={close} alt="close" />
          </button>
        </div>

        <div className={rowClassName}>
          <div className={`${textClassName} font-normal`}>
            Please promptly migrate the funds from your pre-deposit program to
            Fuel Ignition. Failure to do so may result in points accrued being
            forfeited.
          </div>
          <a
            href={learnMoreLink}
            target="_blank"
            rel="noopener noreferrer"
            className={buttonClassName}
          >
            Learn more
            <img src={arrowRight} alt="arrowRight" />
          </a>
        </div>

        <div className={rowClassName}>
          <div className={`${textClassName} font-normal`}>
            To ensure that the mTokens you deposit into Fuel Ignition continue
            to earn CeDeFi points rewards, please bind your Fuel Ignition
            address.
          </div>
          <BindFuelAddress />
        </div>
      </div>
    </div>
  );
};
