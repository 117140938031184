import config from '@/config';

const BASE_URL = config.BACKEND_BASE_URL;
const NEW_PARADIGM_BASE_URL = config.NEW_PARADIGM_BACKEND_BASE_URL;

const ENDPOINTS = {
  earnMoreInfo: `${BASE_URL}/m-token-adaption/config`,
  mindInfo: `${BASE_URL}/m-token-adaption/mind-info`,
  userMTokenBalance: `${BASE_URL}/m-token-adaption/user-balance`,
  //blackList
  walletBlock: `${BASE_URL}/auth/is_sanctioned_address`,
  // login
  userInfo: `${BASE_URL}/user/userInfo`,
  getSignData: `${BASE_URL}/auth/signData`,
  login: `${BASE_URL}/auth/login`,
  logout: `${BASE_URL}/auth/logout`,
  checkInviteCode: `${BASE_URL}/auth/checkInviteCode`,
  getAuthUrl: `${BASE_URL}/auth/getAuthUrl`,

  //check-in
  checkInStatus: `${BASE_URL}/stake_scores/daily_check_in_status`,
  checkIn: `${BASE_URL}/stake_scores/daily_check_in`,

  // history
  getStakingHistory: `${BASE_URL}/stake/get_stake_record`,
  getStakingHistoryStatus: `${BASE_URL}/stake/get_stake_record_with_status`,

  //points
  pointsStatus: `${BASE_URL}/stake_scores/status`,
  getPointsBoxId: `${BASE_URL}/stake_scores/get_open_box_request_id`,
  openPointsBox: `${BASE_URL}/stake_scores/open`,
  queryEligibleExtra: `${BASE_URL}/stake_scores/query_eligible_extra`,
  queryMantaEligibleExtra: `${BASE_URL}/stake_scores/query_eligible_manta_stake_v2`,
  stakeTvl: `${BASE_URL}/stake/tvl`,

  usdPrices: `${BASE_URL}/token_price/prices`,

  userInviteInfo: `${BASE_URL}/np-team-contrib/user_invite_info`,
  leaderboard: `${BASE_URL}/leaderboard`,
  leaderboardUser: `${BASE_URL}/leaderboard/user`,
  updateUserInfo: `${BASE_URL}/np-user/updateUserInfo`,
  checkRich: `${BASE_URL}/np-user/checkRich`,
  checkInitReward: `${BASE_URL}/np-user/check_init_reward`,
  applyInitReward: `${BASE_URL}/np-user/apply_init_reward`,
  checkInitRewardBox: `${BASE_URL}/staking_box/initial_reward`,
  openTwitter: `${BASE_URL}/np-auth/openTwitter`,

  luckyBoxStatus: `${BASE_URL}/lucky_box/status`,
  luckyBoxOpen: `${BASE_URL}/lucky_box/open`,
  queryEachTypeAmountBySender: `${BASE_URL}/bridge/deposit/query_each_type_amount_by_sender`,
  queryDepositAmountBySender: `${BASE_URL}/bridge/deposit/query_deposit_amount_by_sender`,
  nftStatus: `${BASE_URL}/nft/status`,
  nftBalance: `${BASE_URL}/nft/balance`,
  queryNftBalance: `${BASE_URL}/nft/query_nft_balance`,
  nftUnclaimedNft: `${BASE_URL}/nft/unclaimed_nft`,
  nftUnclaimedSignature: `${BASE_URL}/nft/get_unclaimed_signature`,
  npStatics: `${BASE_URL}/npdata/wallet_statics`,
  npApy: `${BASE_URL}/npdata/npApy`,
  depositStatus: `${BASE_URL}/bridge/deposit/status`,
  depositMore: `${BASE_URL}/lucky_box/get_minimum_deposit_amount`,
  claimStatus: `${BASE_URL}/airdrop/claimNft`,
  stakingList: `${BASE_URL}/staking/staking_list`,
  stakingStatus: `${BASE_URL}/staking_box/status`,
  getXRequestId: `${BASE_URL}/staking_box/get_x_request_id`,
  stakingBoxOpen: `${BASE_URL}/staking_box/open`,
  withdrawHistory: `${NEW_PARADIGM_BASE_URL}/withdraw/query_record_by_sender`,
  mStonePoints: `${BASE_URL}/stone-points/info`,
  burnHistory: `${BASE_URL}/burn_nft/query_record_by_sender`,
  stakingTVL: `${BASE_URL}/staking/tvl`,
  claimToken: `${BASE_URL}/claim_token/claim_manta_token`,
  getClaimableScores: `${BASE_URL}/claim_token/get_claimable_scores`,
  claimTokenSecondRound: `${BASE_URL}/manta_reward/claim_manta_token`,
  getClaimableScoresSecondRound: `${BASE_URL}/manta_reward/get_claimable_scores`,

  // fuel
  fuelWallets: `${BASE_URL}/m-token-adaption/fuel/wallets`,
  fuelSignData: `${BASE_URL}/m-token-adaption/fuel/sign_data`,
  fuelBindWallet: `${BASE_URL}/m-token-adaption/fuel/bind_wallet`,
  fuelDelWallet: `${BASE_URL}/m-token-adaption/fuel/del_wallets ` // testing use only
};

export default ENDPOINTS;
