import { useState } from 'react';
import BaseModal from '@/components/BaseModal';

import type { ModalProps } from 'antd';

export enum ModalCloseStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  CANCEL = 'cancel'
}

export interface IModalContentProps<T = any> {
  open: boolean;
  onCancel: (status?: ModalCloseStatus) => void;
  onOpen: () => void;
  data: T;
}

export interface IModalProps extends Omit<ModalProps, 'onCancel'> {
  cancelCallback?: (status?: ModalCloseStatus) => void;
  data?: IModalContentProps['data'];
  onCancel?: (status?: ModalCloseStatus) => void;
}

function useModal<T = any>(
  modalContent: (...args: any) => React.JSX.Element,
  initialOpts: Partial<IModalProps> = {},
  modalType?: string
): [
  React.JSX.Element,
  {
    open: boolean;
    onOpen: (res?: T) => void;
    onCancel: (status?: ModalCloseStatus) => void;
    updateOpts: (newOpts: Partial<IModalProps>) => void;
  }
] {
  const [opts, setOpts] = useState(initialOpts);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<Partial<T>>();

  const onOpen = (res?: Partial<T>) => {
    res && setData(res);
    setOpen(true);
  };

  const onCancel = (status?: ModalCloseStatus) => {
    setOpen(false);
    opts?.cancelCallback && opts?.cancelCallback(status);
  };

  const updateOpts = (newOpts: Partial<IModalProps>) => {
    setOpts(prevOpts => ({ ...prevOpts, ...newOpts }));
  };

  const finData = { ...data, ...(opts?.data || {}) };
  const ThisModal = open ? (
    <BaseModal<Partial<T>>
      data={finData}
      open={open}
      modalType={modalType}
      // @ts-ignore
      onCancel={(_e: React.MouseEvent<HTMLElement>) => {
        onCancel(ModalCloseStatus.CANCEL);
      }}
      {...opts}
    >
      {modalContent({
        open,
        onCancel: (status: ModalCloseStatus = ModalCloseStatus.CANCEL) => {
          onCancel(status);
        },
        onOpen,
        data: finData
      })}
    </BaseModal>
  ) : (
    <></>
  );

  return [
    ThisModal,
    {
      open,
      onOpen,
      onCancel,
      updateOpts
    }
  ];
}
export default useModal;
