import BindFuelAddress from '@/components/Fuels/BindFuelAddress';
import MyBoundAddress from '@/components/Fuels/MyBoundAddress';
import { COMMON_STYLES } from '@/constants/fuelStyles';
import { useFuelWalletsList } from '@/hooks/useFuelWalletsList';
import classNames from 'classnames';

const FuelBanner = () => {
  const { fuelWallets } = useFuelWalletsList();
  const hasBoundFuelAddress = fuelWallets?.length > 0;
  return (
    <div className="flex flex-col sm:flex-row justify-center items-center gap-4 max-md:mb-4">
      <div
        className={classNames(
          `max-w-[30.125rem] p-2 bg-fuel-primary/40 rounded-lg border border-fuel-primary flex-col justify-center items-start gap-2.5`,
          hasBoundFuelAddress ? 'invisible' : ''
        )}
      >
        <div className={COMMON_STYLES.text}>
          Bind your Fuel Ignition address to ensure that the mTokens you deposit
          into Fuel Ignition continue to earn CeDeFi points rewards.
        </div>
        <BindFuelAddress gap={2} />
      </div>
      <MyBoundAddress />
    </div>
  );
};

export default FuelBanner;
